
import React, { useState } from "react";
import logo from "../Assets/Images/logo.jpg";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setActiveDropdown(null);
  };

  const handleDropdownClick = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <div className="navbar bg-white w-full z-10 text-red-500 font-bold fixed h-20">
      <div className="container mx-auto flex items-center justify-between px-4 md:px-8 py-8">
        <a href="/" className=" text-xl" style={{ maxWidth: "150px", height: "80px" }}>
          <img src={logo} alt="Logo" style={{ maxWidth: "55%", height: "auto", marginTop: "0px " }} />
        </a>
        {/* Menu button for small screens */}
        <button className="block md:hidden btn btn-ghost mr-10" onClick={toggleMenu}>
  {/* Menu Icon */}
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
  </svg>
</button>

        {/* Navigation links for larger screens */}
        <ul className="hidden md:flex menu menu-horizontal">
          <li className="mr-4">
            <a href="/">Home</a>
          </li>
          <li className="mr-4 relative">
            <a onClick={() => handleDropdownClick(1)}>About Us</a>
            {/* Dropdown content */}
            {activeDropdown === 1 && (
              <ul className="submenu bg-white text-red-500 py-2 absolute top-full left-0 w-60">
                <li><a href="/us">Who we are</a></li>
                <li><a href="/location">Where we work</a></li>
                <li><a href="/leadership">Our Leadership</a></li>
              </ul>
            )}
          </li>
          <li className="mr-4 relative">
            <a onClick={() => handleDropdownClick(2)}>Our Work</a>
            {/* Dropdown content */}
            {activeDropdown === 2 && (
              <ul className="submenu bg-white text-red-500 py-2 absolute top-full left-0 w-60">
                <li><a href="/youth">Youth Empowerment</a></li>
                <li><a href="/gender">Gender Advocacy</a></li>
                <li><a href="/climate">Climate Action</a></li>
                <li><a href="/art">Art and Technology</a></li>
              </ul>
            )}
          </li>
          <li className="mr-4">
            <a href="/campaignpage">Campaign</a>
          </li>
          <li className="mr-4">
            <a href="/contact">Contact Us</a>
          </li>
          <li><a href="/jobs">Jobs</a></li>
        </ul>
      </div>
      {/* Navigation links for small screens */}
      <ul className={`${isOpen ? "block" : "hidden"} md:hidden menu menu-vertical w-full`}>
        <li><a href="/">Home</a></li>
        <li>
          <details open={activeDropdown === 1} onClick={() => handleDropdownClick(1)}>
            <summary>About Us</summary>
            <ul className="p-2 bg-white rounded-t-none">
              <li><a href="/us">Who we are</a></li>
              <li><a href="/location">Where we work</a></li>
              <li><a href="/leadership">Our Leadership</a></li>
            </ul>
          </details>
        </li>
        <li>
          <details open={activeDropdown === 2} onClick={() => handleDropdownClick(2)}>
            <summary>Our Work</summary>
            <ul className="p-2 bg-white rounded-t-none">
              <li><a href="/youth">Youth Empowerment</a></li>
              <li><a href="/gender">Gender Advocacy</a></li>
              <li><a href="/climate">Climate Action</a></li>
              <li><a href="/art">Art and Technology</a></li>
            </ul>
          </details>
        </li>
        <li><a href="/campaignpage">Campaign</a></li>
        <li><a href="/contact">Contact Us</a></li>
        <li><a href="/jobs">Jobs</a></li>
      </ul>
    </div>
  );
}

export default Navbar;
