import "./App.css";
import emailjs from "@emailjs/browser";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "../src/components/Footer";
import Us from "./components/Us";
import Location from "./components/Location";
import Leadership from "./pages/Leadership";
import Contact from "./pages/Contact";
import Youth from "./pages/Youth";
import Climate from "./pages/Climate";
import Gender from "./pages/Gender";
import Campaign from "./pages/Campaign";
import art from "./pages/art";
import jobs from "./pages/jobs";
import CampaignCover from "./pages/CampaignCover";
import Activities from "./pages/Activities";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar className=""/>
        <Routes>
          <Route path="/" exact Component={Home} />
          <Route path="/us" exact Component={Us} />
          <Route path="/location" exact Component={Location} />
          <Route path="/leadership" exact Component={Leadership} />
          <Route path="/contact" exact Component={Contact} />
          <Route path="/youth" exact Component={Youth} />
          <Route path="/climate" exact Component={Climate} />
          <Route path="/gender" exact Component={Gender} />
          <Route path="/campaign" exact Component={Campaign} />
          <Route path="/art" exact Component={art} />
          <Route path="/jobs" exact Component={jobs} />
          <Route path="/campaignpage" exact Component={CampaignCover} />
          <Route path="/activities" exact Component={Activities}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
