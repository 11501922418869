import React from 'react'
import manager from '../Assets/Images/partnership-manager.jpeg'
import intern from '../Assets/Images/intern.jpeg'

function jobs() {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
    <img
      src={manager}
      alt="Job Poster"
      className="max-w-[90%] max-h-[90vh] object-contain rounded-lg shadow-lg"
    />
    <img
      src={intern}
      alt="Job Poster"
      className="max-w-[90%] max-h-[90vh] object-contain rounded-lg shadow-lg"
    />
  </div>
  )
}

export default jobs
