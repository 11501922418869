import React from "react";
import galleryImg1 from "../Assets/Images/ian6.jpg";
import galleryImg2 from "../Assets/Images/ian7.jpg";
import galleryImg3 from "../Assets/Images/ian8.jpg";
import galleryImg4 from "../Assets/Images/ian9.jpg";
import galleryImg5 from "../Assets/Images/ian10.jpg";
import galleryImg6 from "../Assets/Images/ian11.jpg";
import galleryImg7 from "../Assets/Images/ian12.jpg";

const Activities = () => {
  const galleryItems = [
    { id: 1, image: galleryImg1 },
    { id: 2, image: galleryImg2 },
    { id: 3, image: galleryImg3 },
    { id: 4, image: galleryImg4 },
    { id: 5, image: galleryImg5 },
    { id: 6, image: galleryImg6 },
    { id: 7, image: galleryImg7 },
  ];

  return (
    <div className="container mx-auto pt-24 pb-12 ">
      {/* Written content like an introduction */}
      <div className="text-center mb-12 px-6">
        <h1 className="text-4xl font-extrabold text-red-500 mb-6 leading-tight">
          Kaskazini Mobile Film Project Newsletter: Empowering Northern Kenya’s
          Youth Through Digital Storytelling
        </h1>
        <p className="text-lg text-white mb-8 mx-auto max-w-3xl">
          <span className="block font-semibold text-red-500 mb-2">
            Two Weeks of Training and Impactful Storytelling
          </span>
          The Kaskazini Mobile Film Project is thrilled to share the success of
          our recent two-week training program, where we welcomed 20 young
          creatives to embark on a transformative storytelling journey. This
          cohort, selected from a competitive pool of 290 applicants, represents
          the immense untapped potential of Northern Kenya’s youth.
          <br />
          <br />
          The training covered essential aspects of filmmaking, including mobile
          photography, videography, editing, and digital advocacy. A highlight
          of the program was the collaborative production of a short film in
          partnership with the Ngaremara community. The film, rooted in local
          stories and cultural heritage, was screened for the community,
          sparking conversations and pride in local narratives.
        </p>
      </div>

      {/* Gallery grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {galleryItems.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg shadow-lg group transform transition duration-500 hover:scale-105"
          >
            <img
              src={item.image}
              alt={`Random image ${item.id}`}
              className="w-full h-56 object-cover"
            />
          </div>
        ))}
      </div>

      {/* Optional content below the gallery */}
      <div className="mt-12 text-center">
        <p className="text-lg text-white">
          Engaging Communities During 16 Days of Activism Our students took
          their newfound skills beyond the classroom during the 16 Days of
          Activism against Gender-Based Violence (GBV). They developed powerful
          short skits, performed for their communities, and participated in a
          roadshow campaign to create awareness about GBV. These activities
          demonstrated the potential of storytelling as a tool for advocacy and
          social change. !
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {galleryItems.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg shadow-lg group transform transition duration-500 hover:scale-105"
          >
            <img
              src={item.image}
              alt={`Random image ${item.id}`}
              className="w-full h-56 object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Activities;
